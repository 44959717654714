<template>
  <section id="Home">
    <div class="name">
      <h1>Luca Bockmann</h1>
      <div>
        <div>SW Architekt & Designer</div>
        <div>© 2025</div>
      </div>
    </div>



    <div class="links">
      <div v-for="(link, index) in $store.state.home.data" :key="index">
        <a :href="link.href" v-text="link.linkText"></a>
      </div>

    </div>

  </section>
</template>

<script>

// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {
  },

}
</script>


<style lang="scss">
#Home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .name {
    position: relative;
    padding: 20px 40px;
    z-index: 1000;
    color: #fff;
    text-size-adjust: 80%;

    h1 {
      margin: 0;
      font-size: 13vw;

    }

    div {
      font-size: 8.5vw;
    }
  }

  .links {
    z-index: 1000;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    padding: 20px 40px;

    a {
      font-size: 4vw;
    }
  }

}
</style>
