<template>
  <div id="app">
    <div class="content-wrapper">
      <router-view />


    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      year: null,
    }
  },

  mounted() {
    this.year = new Date().getFullYear();
  },

  created() {
    this.getBackground()
    document.addEventListener('load', this.getBackground)
  },

  methods: {
    getBackground() {
      var colors = this.$store.state.colors
      var background = this.$store.state.backgrounds.data

      var randomColor = colors[Math.floor(Math.random() * colors.length)]
      var randomBackgroundImage = background[Math.floor(Math.random() * background.length)];

      document.body.style.backgroundImage = "url('" + randomBackgroundImage.url + "')";
      document.body.style.setProperty("--background-blend-color", randomColor)
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Inter";
  src: url("@/assets/Inter-Medium.ttf");
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  background: #121212;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &::before {
    content: "";
    background: var(--background-blend-color);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    mix-blend-mode: darken;
    pointer-events: none;
  }

  &::after {
    content: "";
    background: #333;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    mix-blend-mode: lighten;
    pointer-events: none;
  }

}

h2 {
  font-size: 14px;
  opacity: .6;
  margin-bottom: 12px;
  margin-top: 0;
}

p {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

small {
  opacity: .6;
}

#app {
  font-family: "Inter", sans-serif;
  color: #fff;
  height: 100%;

  .content-wrapper {
    height: 100%;
  }



}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 4px;

  }
}

a {
  color: #fff;
  text-decoration: none;
  position: relative;
  font-size: 16px;

  &::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%);
    right: -25px;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  span {
    padding-left: 4px;
    opacity: .45;
  }
}
</style>
