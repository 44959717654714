import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    home: {
      data: [
        { linkText: "Mail Luca", href: "mailto:kontakt@lucabockmann.de", newTab: false },
        { linkText: "Mail Studio", href: "mailto:studio@bylucabockmann.de", newTab: false },
        { linkText: "Zum Studio", href: "https://studio.bylucabockmann.de", newTab: true },
        { linkText: "Inventar", href: "https://things.bylucabockmann.de", newTab: true },
        { linkText: "instagram", href: "https://www.instagram.com/studio.by.lucabockmann/", newTab: true },
        { linkText: "logs", href: "https://www.threads.net/@lucabockmann", newTab: true },
        { linkText: "cosmos", href: "https://www.cosmos.so/lucabockmann", newTab: true },
      ]
    },


    backgrounds: {
      data: [
        { url: require("@/assets/bg/architecture.webp"), alt: "Bild eines Brutalistischen Gebaeudes!" },
        { url: require("@/assets/bg/italien.webp"), alt: "Bild von mir in Italien" },
        { url: require("@/assets/bg/ich-esse.webp"), alt: "Bild von mir wo ich ein Blatt esse!" },
        { url: require("@/assets/bg/ich.webp"), alt: "Bild von mir!" },
        { url: require("@/assets/bg/ich-arbeite.webp"), alt: "Bild von mir wo ich arbeite" },

        { url: require("@/assets/bg/luca.webp"), alt: "Bild von mir" },
        { url: require("@/assets/bg/luca-selfie.webp"), alt: "Bild von mir wo ich das 'peace' Zeichen zeige" },
        { url: require("@/assets/bg/architecture-two.webp"), alt: "Bild von Brutalistischen Gebaude in Mannheim" },
        { url: require("@/assets/bg/architecture-three.webp"), alt: "Bild von einem Brutalistischen Gebaeude in Sachsen-Anhalt" },
        { url: require("@/assets/bg/sir.webp"), alt: "Bild von mir und meinem Kater Sir" },

      ]
    },
    colors: [
      "#B3001B",
      "#5603AD",
      "#058E3F",
      "#2EC0F9",
      "#FFFD77",
      "#CEFF1A",
      "#1B28A2"
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
